<template>
  <a-button class="editable-add-btn" @click="openModal" style="margin-bottom: 16px">Добавить</a-button>
  <a-modal
    class="reference-create-modal"
    title="Добавить запись"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="reference" @keyup.enter="create">
      <div class="col-md-12">
        <a-upload
            name="icon"
            list-type="picture-card"
            class="logo-uploader"
            :show-upload-list="false"
            :headers="getHeaders()"
            :action="getUrl"
            :before-upload="beforeUpload"
            @change="uploadImage"
        >
          <img v-if="iconUrl" :src="iconUrl" alt="avatar"/>
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <PlusOutlined v-else></PlusOutlined>
            <div class="ant-upload-text">Логотип</div>
          </div>
        </a-upload>
        <a-form-item label="Название записи">
          <a-input
            ref="referenceName"
            placeholder="Введите название записи"
            v-model:value="reference.title"
          />
        </a-form-item>
        <a-form-item label="Переводы" v-if="reference.locales.length">
          <div class="container pb-3" v-for="(model, key) in reference.locales" :key="key">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <a-input placeholder="Введите название" v-model:value="model.title"></a-input>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <a-select class="pl-3" v-model:value="model.locale">
                    <a-select-option v-for="(locale, lKey) in locales" :key="lKey" :value="locale.short">{{ locale.name }}</a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-button
          @click="addLocale"
          shape="round"
          type="link"
          class="px-0"
        >
          <template #icon>
            <PlusCircleOutlined />
          </template>Добавить перевод</a-button>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, nextTick, watch} from 'vue';
import apiClient from "@/services/axios";
import Reference from "@/models/Reference";
import {LoadingOutlined, PlusCircleOutlined, PlusOutlined} from '@ant-design/icons-vue';
import {getHeaders} from "@/helpers/header";
import {getBase64} from "@/helpers/base64";
import {message} from "ant-design-vue";

const props = defineProps({
  locales: {
    type: Array,
    default: null,
  },
  modal: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['reload']);

let iconUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  initialReference = new Reference({}),
  referenceName = ref("referenceName"),
  reference = reactive(initialReference);

const
  uploadImage = info => {
    iconUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      reference.icon = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        iconUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  addLocale = () => {
    reference.locales.push({
      id: null,
      title: null,
      locale: props.locales.find(() => true).short,
    })
  },
  openModal = () => {
    modalVisible.value = true;
    iconUrl.value = "";
    nextTick(() => referenceName.value?.focus());
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/filter/upload-image'
  },
  resetForm = () => {
    Object.assign(reference, new Reference({}));
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(reference);
    model.type = 'cuisine'

    return apiClient.post('/filter/create', model).then(function (response) {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      modalVisible.value = false;
      confirmLoading.value = false;
      emit('reload')
    })
  }

watch(() => {
  if (props.modal) {
    openModal()
  }
})
</script>

<style scoped>
@import "./create.css";
</style>
